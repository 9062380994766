import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AlertBox extends Vue {
  @Prop() errorMessage!: any;
  @Prop() loggedOutByInactivity: boolean | undefined;
  @Prop() isSuccess: boolean | undefined;

  /**
   * If we have an error.
   */
  get hasError(): boolean {
    return this.errorMessage !== null;
  }

  /**
   * Error message.
   */
  get error(): string {
    // Quick switch for a common error message in Dutch (as the errors are comming from Firebase in English)
    if (this.$i18n.locale === 'nl' && this.errorMessage === 'The email address is already in use by another account.') {
      return 'Dit e-mailadres is al in gebruik.';
    }

    return this.errorMessage || '';
  }
}
