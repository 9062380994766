import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import VueRecaptcha from 'vue-recaptcha';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { State } from '@/store/models';
import { linkAlternate, metaDescriptions, metaLocale, metaURL } from '@/helpers/meta';
import { titleTemplate } from '@/helpers/meta/title-template';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import AlertBox from '@/components/common/auth/alert-box/AlertBox.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';

@Component({
  components: {
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
    FormInput,
    AlertBox,
    BannerLegal,
  },
})

export default class Login extends Vue {
  @Prop() isAuthPage!: boolean;

  metaInfo(): MetaInfo {
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    return {
      title: titleTemplate(this.$t('meta.login.title').toString()),
      link: [
        ...linkAlternate(path, availableLocales),
      ],
      meta: [
        ...metaDescriptions(this.$t('meta.login.description').toString()),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
      ],
    };
  }

  email: string = '';
  password: string = '';
  errorMessage: string | null = null;
  loggedOutByInactivity = false;
  errorSubmit = false;

  @Action logIn!: Function;
  @Action logOut!: Function;
  @Action openModal!: Function;
  @Action closeModal!: Function;
  @Action activateInitialTooltip!: Function;

  @StateClass auth!: State['auth'];
  @StateClass operations!: State['operations'];
  @StateClass authModal!: State['authModal'];

  @Watch('auth', { deep: true })
  onAuthChanged(newAuth: State['auth'], oldAuth: State['auth']): void {
    this.undoErrors();
    // @ts-ignore
    this.$refs.invisibleRecaptcha.reset();

    if (newAuth?.action === 'LOGIN_SUCCESS' && oldAuth?.status !== newAuth.status) {
      if (this.authModal.routeTo) {
        this.$router.replace(this.authModal.routeTo).catch((): void => {});
      } else if (newAuth?.metadata?.lastSignInTime === newAuth?.metadata?.creationTime) {
        // lastSignInTime is not undefined but equals to creationTime on first login
        this.$router.replace('/properties').catch((): void => {});
        this.activateInitialTooltip();
      } else {
        this.$router.replace((this.$route.query.from as string) || '/account').catch((): void => {});
      }
      this.closeModal();
    }

    if (newAuth?.action === 'LOGIN_ERROR' && oldAuth?.status !== newAuth.status) {
      this.errorMessage = newAuth.error;
      this.errorSubmit = true;
    }

    if (newAuth?.action === 'LOGIN_WARNING' && newAuth.status !== oldAuth?.status) {
      if (!newAuth.emailVerified) {
        this.errorMessage = `${this.$t('auth.login.pleaseActivate')}.`;
      } else {
        this.errorMessage = `${this.$t('auth.login.error')}`;
      }
    }
  }

  created(): void {
    // if (!this.recaptchaLoaded) {
    //   this.loadRecaptcha();
    // }

    if (this.operations && this.operations.name && this.operations.name === 'IDLE_LOGOUT') {
      // @ts-ignore
      this.loggedOutByInactivity = this.isAppIdle;
    }
  }

  beforeDestroy(): void {
    // @ts-ignore
    this.$refs.invisibleRecaptcha.reset();
  }

  /**
   * Get reCaptcha key from environment.
   *
   * @see https://www.google.com/recaptcha/admin#site/343220696
   */
  get recaptchaKey(): string {
    return process.env.VUE_APP_RECAPTCHA as string;
  }

  /**
   * If we have an error..
   */
  get hasError(): boolean {
    return this.errorMessage !== null;
  }

  /**
   * Remove error state.
   */
  undoErrors(): void {
    this.errorMessage = null;
  }

  /**
   * Execute reCaptcha and then login.
   *
   * @see https://github.com/DanSnow/vue-recaptcha
   */
  captchaAndLogin(): void {
    if (process.env.NODE_ENV === 'development') {
      this.logIn({ email: this.email, password: this.password });
      return;
    }
    // @ts-ignore
    this.$refs.invisibleRecaptcha.execute();
    this.errorMessage = null;
  }

  /**
   * reCaptcha callback.
   */
  onVerify(response): void {
    if (response) {
      this.logIn({ email: this.email, password: this.password });
    }
  }

  /**
   * reCaptcha expired callback.
   */
  onExpired(): void {
    this.errorMessage = `${this.$t('auth.login.captchaTimeExpired')}.${this.$t('auth.login.tryAgain')}.`;
  }
}
