import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { ValidationObserver } from 'vee-validate';
import { State } from '@/store/models';
import { linkAlternate, metaDescriptions, metaLocale, metaURL } from '@/helpers/meta';
import { titleTemplate } from '@/helpers/meta/title-template';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import AlertBox from '@/components/common/auth/alert-box/AlertBox.vue';
import Loader from '@/components/common/loader/Loader.vue';
import { name } from '../../../../../whitelabel.config';

@Component({
  components: {
    AlertBox,
    ValidationObserver,
    FormInput,
    Loader,
  },
})

export default class Register extends Vue {
  @Prop() isAuthPage!: boolean;

  metaInfo(): MetaInfo {
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    return {
      title: titleTemplate(this.$t('meta.register.title').toString()),
      link: [
        ...linkAlternate(path, availableLocales),
      ],
      meta: [
        ...metaDescriptions(this.$t('meta.register.description', { name }).toString()),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
      ],
    };
  }

  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  emailSent: boolean = false;
  private errorMessage: string | null = null;
  errorSubmit: boolean = false;
  isLoading: boolean = false;

  @Action signUp!: Function;
  @Action openModal!: Function;

  @StateClass auth!: State['auth'];
  @StateClass operations!: State['operations'];

  @Watch('auth', { deep: true })
  onUserChanged(newAuth: State['auth'], oldAuth: State['auth']): void {
    this.errorMessage = null;

    if (newAuth && newAuth.status === 'processing') {
      this.isLoading = true;
    }

    if (oldAuth
      && oldAuth.status === 'processing'
      && newAuth
      && newAuth.status === 'error'
      && newAuth.action === 'SIGNUP_ERROR') {
      this.isLoading = false;
      this.errorMessage = newAuth.error;
      this.errorSubmit = true;
    }

    if (newAuth && newAuth.action === 'SIGNUP_SUCCESS' && newAuth.status === 'success') {
      this.isLoading = false;
      this.emailSent = true;
    }
  }

  /**
   * Error message.
   */
  get error(): string {
    return this.errorMessage || '';
  }

  /**
   * If we have an error..
   */
  get hasError(): boolean {
    return this.errorMessage !== null;
  }

  get from(): string {
    const from = this.$route.query.from;
    return from ? `?from=${from}` : '';
  }
}
